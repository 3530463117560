import AppGridContainer from "@crema/components/AppGridContainer"
import IntlMessages from "@crema/helpers/IntlMessages";
import { Grid } from "@mui/material";
import StatsDirCard from "modules/report/StatsDirCard";
import React from "react"
import PropTypes from 'prop-types';
import { BiDollar, BiImage } from "react-icons/bi";
import { ccyFormat } from "@crema/helpers/Common";
import RecordStats from "./RecordStats";
import { useSelector } from "react-redux";
import RecordListing from "./RecordList";
import RecordHour from "./RecordHour";
import SalesState from "./SalesState";
import { MdCarRepair } from "react-icons/md";

const AdminDashboard = ({ stats }) => {
  const { day, month, year } = stats;
  const recordHour = useSelector(({ dashboard }) => dashboard.recordHour);
  const saleYears = useSelector(({ dashboard }) => dashboard.saleYears);
  const saleMonths = useSelector(({ dashboard }) => dashboard.saleMonths);
  return (

    <AppGridContainer spacing={7}>

      <Grid item xs={12} sm={12} md={4} lg={3}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={12} lg={12} mb={4}>
            <StatsDirCard data={{
              id: 1,
              name: <IntlMessages id='stats.totalRecordToday' />,
              value: day && day?.count ? ccyFormat(day?.count + "") : "0",
              icon: <MdCarRepair />,
              color: '#0A8FDC',
            }} />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={12} mb={4}>
            <StatsDirCard data={{
              id: 3,
              name: <IntlMessages id='stats.totalAmountToday' />,
              value: day && day?.totalAmount ? ccyFormat(day?.totalAmount + "") : "0",
              icon: <BiDollar />,
              color: '#11C15B',
            }} />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={12} mb={4}>
            <StatsDirCard data={{
              id: 1,
              name: <IntlMessages id='stats.totalRecordMonth' />,
              value: month && month?.count ? ccyFormat(month?.count + "") : "0",
              icon: <MdCarRepair />,
              color: '#0A8FDC',
            }} />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={12} mb={4}>
            <StatsDirCard data={{
              id: 1,
              name: <IntlMessages id='stats.totalRecordYear' />,
              value: year && year?.count ? ccyFormat(year?.count + "") : "0",
              icon: <BiDollar />,
              color: '#0A8FDC',
            }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <RecordListing />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        <RecordHour data={recordHour} />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        <SalesState
          salesState={saleYears?.salesState}
          chartData={saleYears?.data}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <RecordStats chartData={saleMonths?.data} />
      </Grid>

    </AppGridContainer>
  )
}

export default AdminDashboard;

AdminDashboard.propTypes = {
  stats: PropTypes.object,
};