import { createReducer } from '@reduxjs/toolkit';

const initialData = {
  cities: [
    { key: 'ho-chi-minh', name: 'Ho Chi Minh' }
  ],
  districts: [
    { key: 'quan-1', name: 'Quan 1' }
  ],
  wards: [
    { key: 'phuong-1', name: 'Phuong 1' }
  ]
};

const utilReducer = createReducer(initialData, (builder) => {
  // builder.addCase(SET_INITIAL_PATH, (state, action) => {
  //   state.initialPath = action.payload;
  // });
});

export default utilReducer;
