import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const AsynchronousSelect = ({ getData, label, onChange, defaultValue }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const data = await getData();
      setLoading(false);
      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleChange = (newValue) => {
    onChange(newValue?.value || '');
    setValue(newValue);
  }

  React.useEffect(() => {
    if (defaultValue && options.length) {
      const currentValue = options.find(item => item.value === defaultValue);
      if (currentValue) {
        setValue(currentValue);
      }
    }
  }, [defaultValue, options]);
  return (
    <Autocomplete
      id='asynchronous-demo'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      // getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      onChange={(event, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Select'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

AsynchronousSelect.propTypes = {
  getData: PropTypes.func,
  label: PropTypes.any,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any
}

export default AsynchronousSelect;
