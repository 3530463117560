import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsynchronousSelect from 'component/common/asynchronous-select';
import jwtAxios from '@crema/services/auth/jwt-auth';
import IntlMessages from '@crema/helpers/IntlMessages';

const CategorySelect = ({ onChange, defaultValue, companyId, group, label, fullResult }) => {

  const [items, setItems] = useState([]);

  const getData = async () => {
    const { data } = (await jwtAxios.get('categories', {
      params: {
        companyId,
        page: 0,
        take: 100,
        group
      },
    })).data;
    if (data && data.items && data.items.length) {
      setItems(data.items);
      return data.items.map(item => {
        return {
          label: item.name,
          value: item._id
        }
      })
    }
    return [];
  }

  const onChangeValue = (value) => {
    if (fullResult) {
      onChange(items.find(item => item._id === value));
    } else {
      onChange(value)
    }
  }

  return (
    <AsynchronousSelect getData={getData} onChange={onChangeValue} defaultValue={defaultValue} label={label || <IntlMessages id={`category.${group}.selectCategory`} />} />
  )
}

CategorySelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  companyId: PropTypes.string,
  group: PropTypes.string,
  label: PropTypes.any,
  fullResult: PropTypes.bool
}

export default CategorySelect;
