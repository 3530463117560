import {
  GET_RECORD_DETAIL,
  GET_RECORD_LIST,
  GET_RECORD_STATS,
  UPDATED_RECORD
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedRecord: null,
  stats: {
    day: null,
    month: null,
    year: null
  }
};

const recordReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_RECORD_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(GET_RECORD_DETAIL, (state, action) => {
      state.selectedRecord = action.payload;
    })
    .addCase(UPDATED_RECORD, (state, action) => {
      const selectedRecord = action.payload;
      state.selectedRecord = selectedRecord;
      const index = state.items.findIndex(item => item._id === selectedRecord._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedRecord;
        state.items = nextList;
      }
    })
  builder
    .addCase(GET_RECORD_STATS, (state, action) => {
      state.stats = action.payload;
    })
});

export default recordReducer;
