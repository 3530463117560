import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_DASHBOARD_SALE_BY_YEAR,
  GET_DASHBOARD_SALE_BY_MONTH,
  GET_DASHBOARD_RECORD_HOUR_LIST
} from '@crema/constants/ActionTypes';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const onGetDashBoardRecordHour = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    jwtAxios
      .get('dashboard-record-hour', {
        params: {
          ...payload,
          sort: 'dateTime',
          sortType: 'desc'
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DASHBOARD_RECORD_HOUR_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDashBoardSaleYear = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    jwtAxios
      .get('dashboard-sale-year', {
        params: {
          ...payload,
          sort: 'dateTime',
          sortType: 'desc'
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DASHBOARD_SALE_BY_YEAR, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDashBoardSaleMonth = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    jwtAxios
      .get('dashboard-sale-month', {
        params: {
          ...payload,
          sort: 'dateTime',
          sortType: 'desc'
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DASHBOARD_SALE_BY_MONTH, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};