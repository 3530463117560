import {
  CREATE_NEW_COMPANY,
  GET_COMPANY_DETAIL,
  GET_COMPANY_LIST,
  GET_COMPANY_SETTINGS,
  UPDATED_COMPANY
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedCompany: null,
  companySettings: null
};

const companyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_COMPANY_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_COMPANY, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_COMPANY_DETAIL, (state, action) => {
      state.selectedCompany = action.payload;
    })
    .addCase(UPDATED_COMPANY, (state, action) => {
      const selectedCompany = action.payload;
      state.selectedCompany = selectedCompany;
      const index = state.items.findIndex(item => item._id === selectedCompany._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedCompany;
        state.items = nextList;
      }
    })
    .addCase(GET_COMPANY_SETTINGS, (state, action) => {
      state.companySettings = action.payload;
    })
});

export default companyReducer;
