import React, { useEffect, useState } from "react";
import AppDialog from "@crema/components/AppDialog";
import IntlMessages from "@crema/helpers/IntlMessages";
import PropTypes from 'prop-types';
import AppGridContainer from "@crema/components/AppGridContainer";
import { Grid, styled } from "@mui/material";
import AppGrid from "@crema/components/AppGrid";
import AppMedialViewer from "@crema/components/AppMedialViewer";
import clsx from "clsx";
import UpdateRecord from "modules/company-dashboard/RecordList/RecordForm";

const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.cardRadius,
  display: 'block',
  width: '100%',
  cursor: 'pointer',
}));

const ViewRecord = ({ isOpen, onClose, record }) => {
  const [photos, setPhotos] = useState([]);
  const [index, setIndex] = useState(-1);

  const onCloseViewImage = () => {
    setIndex(-1);
  };

  useEffect(() => {
    if (record) {
      let recordPhotos = [];
      if (record.plateImgUrl) {
        recordPhotos = [record.plateImgUrl, ...recordPhotos];
      }
      // if (record.imageAllInfoUrl) {
      //   recordPhotos = [record.imageAllInfoUrl, ...recordPhotos];
      // }
      if (record.bgImgUrl) {
        recordPhotos = [record.bgImgUrl, ...recordPhotos];
      }
      setPhotos(recordPhotos);
    }
  }, [record]);

  return (
    <AppDialog
      dividers
      maxWidth='md'
      // maxScrollHeight={700}
      open={isOpen}
      onClose={() => onClose()}
      title={<IntlMessages id='record.viewRecord' />}
    >
      <AppGridContainer spacing={5}>
        <Grid item xs={12} lg={4} md={4}>
          <div>
            <AppGrid
              data={photos}
              column={1}
              itemPadding={4}
              responsive={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
              }}
              renderRow={(photo, index) => (
                <StyledImage
                  onClick={() => setIndex(index)}
                  className={clsx('card-hover')}
                  key={index}
                  src={photo}
                  alt='user'
                />
              )}
            />
          </div>
          <AppMedialViewer
            index={index}
            medias={photos.map((data) => {
              return {
                url: data,
                mime_type: 'image/*',
              };
            })}
            onClose={onCloseViewImage}
          />
        </Grid>
        <Grid item xs={12} lg={8} md={8}>
          <UpdateRecord record={record} />
        </Grid>
      </AppGridContainer>
    </AppDialog>
  )
}

export default ViewRecord;

ViewRecord.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object
};