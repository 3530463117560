import {
  CREATE_NEW_PLACE,
  GET_PLACE_DETAIL,
  GET_PLACE_LIST,
  UPDATED_PLACE
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedPlace: null
};

const placeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_PLACE_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_PLACE, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_PLACE_DETAIL, (state, action) => {
      state.selectedPlace = action.payload;
    })
    .addCase(UPDATED_PLACE, (state, action) => {
      const selectedPlace = action.payload;
      state.selectedPlace = selectedPlace;
      const index = state.items.findIndex(item => item._id === selectedPlace._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedPlace;
        state.items = nextList;
      }
    })
});

export default placeReducer;
