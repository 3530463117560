import React from 'react';
import PropTypes from 'prop-types';
import StatGraphs from './StatGraphs';
import { useIntl } from 'react-intl';
import { alpha, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppCard from '@crema/components/AppCard';
import AppSelect from '@crema/components/AppSelect';

const RecordCard = styled(AppCard)(({ theme }) => {
  return {
    height: '100%',
    '& .MuiCardHeader-root': {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
  };
});
const RecordAction = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .visitor-action-view': {
      display: 'none',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginRight: 10,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    '& .visitor-action-item': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      lineHeight: 1,
      paddingBottom: 2,
      '&:not(:first-of-type)': {
        borderLeft: `solid 1px ${alpha(theme.palette.text.secondary, 0.2)}`,
        marginLeft: 16,
        paddingLeft: 16,
      },
    },
    '& .dot-visitor': {
      height: 10,
      width: 10,
      marginRight: 4,
      borderRadius: '50%',
    },
  };
});
const RecordStats = (data) => {
  const theme = useTheme();
  const { messages } = useIntl();
  return (
    <RecordCard
      title={messages['dashboard.record.stats'] ?? 'dashboard.record.stats'}
      action={
        <AppSelect
          menus={[
            messages['dashboard.thisMonth'],
          ]}
          defaultValue={messages['dashboard.thisMonth']}
        />
      }
    >
      <StatGraphs data={data?.chartData} />
    </RecordCard>
  );
};
export default RecordStats;

RecordStats.defaultProps = {
  stats: {},
};

RecordStats.propTypes = {
  stats: PropTypes.object,
};
