import {
  CREATE_NEW_USER,
  GET_USER_DETAIL,
  GET_USER_LIST,
  UPDATED_USER
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedUser: null
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_USER_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_USER, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_USER_DETAIL, (state, action) => {
      state.selectedUser = action.payload;
    })
    .addCase(UPDATED_USER, (state, action) => {
      const selectedUser = action.payload;
      state.selectedUser = selectedUser;
      const index = state.items.findIndex(item => item._id === selectedUser._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedUser;
        state.items = nextList;
      }
    })
});

export default userReducer;
