import { combineReducers } from 'redux';
import Company from './Company';
import Common from './Common';
import Settings from './Setting';
import Util from './Util';
import Device from './Device';
import User from './User';
import Camera from './Camera';
import Record from './Record';
import Category from './Category';
import Notification from './Notification';
import Dashboard from './Dashboard';
import Driver from './Driver';
import Vehicle from './Vehicle';
import Place from './Place';
import Trip from './Trip';
import Destination from './Destination';
import Commodity from './Commodity';
const reducers = () =>
  combineReducers({
    company: Company,
    common: Common,
    settings: Settings,
    util: Util,
    device: Device,
    user: User,
    camera: Camera,
    record: Record,
    category: Category,
    notification: Notification,
    dashboard: Dashboard,
    driver: Driver,
    vehicle: Vehicle,
    place: Place,
    trip: Trip,
    destination: Destination,
    commodity: Commodity
  });
export default reducers;
