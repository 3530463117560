import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Driver = React.lazy(() => import('../../../modules/driver'));

export const driverPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/drivers',
      '/drivers/:id'
    ],
    element: <Driver />,
  }
];
