import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import OrderActions from './OrderActions';
import { styled } from '@mui/material/styles';
import { ellipsisLines } from '@crema/helpers/StringHelper'
import { ccyFormat } from '@crema/helpers/Common';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Checkbox } from '@mui/material';
import { getFormattedDate } from '@crema/helpers/DateHelper';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
}));
const TableItem = ({ data, onDelete, checkedRecords, onChangeCheckedRecords, onViewRecord }) => {
  const getUnit = (unit) => {
    switch (unit) {
      case 'm3':
        return <IntlMessages id='record.unit.m3' />
      case 'tan':
        return <IntlMessages id='record.unit.ton' />
      case 'kg':
        return <IntlMessages id='record.unit.kg' />
      default:
        break;
    }
  }

  return (
    <>
      <TableRow key={data.name} className='item-hover'>
        <StyledTableCell align='left' sx={{ width: 130 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              color: 'primary.main',
              minWidth: 100
            }}
            onClick={() => onViewRecord()}
          >
            <span onClick={(event) => event.stopPropagation()}>
              <Checkbox
                sx={{
                  color: 'text.disabled',
                }}
                checked={checkedRecords.includes(data.id)}
                onChange={(event) => onChangeCheckedRecords(event, data.id)}
                color='primary'
              />
            </span>
            <img
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'contain',
                marginRight: 10,
              }}
              src={data?.plateImgUrl || '/assets/images/no-image.jpeg'}
              alt='crema-logo'
            />
            {ellipsisLines(data.name)}
          </Box>
        </StyledTableCell>
        <StyledTableCell align='left'>{data.licensePlate}</StyledTableCell>
        <StyledTableCell align='left'>{data?.device?.name}</StyledTableCell>
        <StyledTableCell align='left'>{data?.camera?.name}</StyledTableCell>
        <StyledTableCell align='left'>{ccyFormat(data.quantity)} {getUnit(data.unit)}</StyledTableCell>
        <StyledTableCell align='left'>{ccyFormat(data.price)}</StyledTableCell>
        <StyledTableCell align='left'>{ccyFormat(data.amount)}</StyledTableCell>
        <StyledTableCell align='left'>{getFormattedDate(data.dateTime, 'DD/MM/YYYY HH:mm:ss')}</StyledTableCell>
        <TableCell align='right'>
          <OrderActions id={data.id} onDelete={onDelete} onViewRecord={onViewRecord} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableItem;

TableItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  checkedRecords: PropTypes.array,
  onChangeCheckedRecords: PropTypes.func,
  onViewRecord: PropTypes.func,
};
