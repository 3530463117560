import {
  CREATE_NEW_DEVICE,
  GET_DEVICE_DETAIL,
  GET_DEVICE_LIST,
  UPDATED_DEVICE
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedDevice: null
};

const deviceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_DEVICE_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_DEVICE, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_DEVICE_DETAIL, (state, action) => {
      state.selectedDevice = action.payload;
    })
    .addCase(UPDATED_DEVICE, (state, action) => {
      const selectedDevice = action.payload;
      state.selectedDevice = selectedDevice;
      const index = state.items.findIndex(item => item._id === selectedDevice._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedDevice;
        state.items = nextList;
      }
    })
});

export default deviceReducer;
