import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Category = React.lazy(() => import('../../../modules/category'));

export const categoryPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/category/:group/list',
      '/category/:id'
    ],
    element: <Category />,
  }
];
