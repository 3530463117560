// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Be Vietnam', serif;
  height: 100%;
}

body {
  line-height: 1.35 !important;
  font-family: 'Be Vietnam', serif;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 14px;
  font-weight: 500;
}

h6 {
  font-size: 12px;
  font-weight: 500;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,4BAA4B;EAC5B,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":root {\n  --light: 300;\n  --regular: 400;\n  --medium: 500;\n  --bold: 600;\n  --extra-bold: 700;\n  font-family: 'Be Vietnam', serif;\n  height: 100%;\n}\n\nbody {\n  line-height: 1.35 !important;\n  font-family: 'Be Vietnam', serif;\n  height: 100%;\n}\n\nhtml {\n  width: 100%;\n  height: 100%;\n}\n\n#root {\n  width: 100%;\n  height: 100%;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\n*,\n*:before,\n*:after {\n  box-sizing: border-box;\n}\n\nimg {\n  max-width: 100%;\n  height: auto;\n}\n\nh1 {\n  font-size: 22px;\n  font-weight: 600;\n}\n\nh2 {\n  font-size: 20px;\n  font-weight: 500;\n}\n\nh3 {\n  font-size: 18px;\n  font-weight: 500;\n}\n\nh4 {\n  font-size: 16px;\n  font-weight: 500;\n}\n\nh5 {\n  font-size: 14px;\n  font-weight: 500;\n}\n\nh6 {\n  font-size: 12px;\n  font-weight: 500;\n}\n\np {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
