import React, { useEffect, useState } from 'react';
import { alpha, Box, Button, FormControl, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AppGridContainer from '@crema/components/AppGridContainer';
import Grid from '@mui/material/Grid';
import IntlMessages from '@crema/helpers/IntlMessages';
import { useDropzone } from 'react-dropzone';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import AppTextField from '@crema/components/AppFormComponents/AppTextField';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { Fonts } from '@crema/constants/AppEnums';
import ProvinceSelect from 'component/address/ProvinceSelect';
import DistrictSelect from 'component/address/DistrictSelect';
import WardSelect from 'component/address/WardSelect';
import ImageUpload from 'component/image-upload';
import { useAuthUser } from '@crema/hooks/AuthHooks';

const AvatarViewWrapper = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&.dropzone': {
      outline: 0,
      '&:hover .edit-icon, &:focus .edit-icon': {
        display: 'flex',
      },
    },
  };
});

const PersonalInfoForm = ({ values, setFieldValue }) => {
  const [avatar, setAvatar] = useState('');
  const { user } = useAuthUser();

  useEffect(() => {
    if (user.avatarUrl) {
      setAvatar(user.avatarUrl)
    }
  }, [])

  return (
    <Form noValidate autoComplete='off'>
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 4 },
        }}
      >
        <IntlMessages id='common.personalInfo' />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 5, lg: 6 },
        }}
      >
        <ImageUpload
          accept="image/*"
          imageUrl={avatar}
          onUploaded={(data) => {
            setFieldValue('avatar', `${data.fileUrl}`);
            setAvatar(data.fileUrl);
          }}
        />
        <Box
          sx={{
            ml: 4,
          }}
        >
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
          >
            {values.displayName}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {values.email}
          </Typography>
        </Box>
      </Box>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='name'
            fullWidth
            label={<IntlMessages id='common.fullName' />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            name='phoneNumber'
            label={<IntlMessages id='common.phoneNumber' />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            name='email'
            fullWidth
            label={<IntlMessages id='common.email' />}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl
            sx={{
              width: '100%',
            }}
            variant='outlined'
          >
            <ProvinceSelect defaultValue={values.city} onChange={(value) => setFieldValue('city', value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl
            sx={{
              width: '100%',
            }}
            variant='outlined'
          >
            <DistrictSelect city={values.city} defaultValue={values.district} onChange={(value) => setFieldValue('district', value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl
            sx={{
              width: '100%',
            }}
            variant='outlined'
          >
            <WardSelect district={values.district} defaultValue={values.ward} onChange={(value) => setFieldValue('ward', value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            sx={{
              width: '100%',
              fontWeight: Fonts.LIGHT
            }}
            variant='outlined'
            label={<IntlMessages id='common.address' />}
            name='address'
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color='primary'
              variant='contained'
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default PersonalInfoForm;
PersonalInfoForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
