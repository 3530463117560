import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { getAuthToken } from "@crema/services/auth/jwt-auth";
import { socketEndpoint } from "@crema/constants/AppConst";
import SocketIO from 'socket.io-client';
import { useAuthUser } from "@crema/hooks/AuthHooks";

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);

const SocketProvider = (
  {
    children
  }
) => {
  const socket = useRef(null);
  const { isAuthenticated } = useAuthUser();
  const [socketState, setSocketState] = useState(null);
  const [socketStatus, setSocketStatus] = useState('initialized');

  const connectSocket = () => {
    const token = getAuthToken();
    const defaultOptions = {
      reconnection: true,
      // reconnectionAttempts: Infinity,
      // reconnectionDelay: 1 * 1000,
      // reconnectionDelayMax: 10 * 1000,
      autoConnect: true,
      transports: ['websocket', 'polling', 'long-polling'],
      // rejectUnauthorized: true
    };
    const options = {
      ...defaultOptions,
      opts: {
        query: token ? `token=${token}` : ''
      },
      query: token ? `token=${token}` : ''
    };

    const socketUrl = socketEndpoint;
    socket.current = SocketIO(socketUrl, options);
    setSocketState(socket.current);

    socket.current.status = 'initialized';
    setSocketStatus('initialized');

    socket.current.on('connect', () => {
      socket.current.status = 'connected';
      setSocketStatus('connected');
    });

    socket.current.on('disconnect', () => {
      socket.current.status = 'disconnected';
      setSocketStatus('disconnect');
    });

    socket.current.on('error', () => {
      socket.current.status = 'failed';
      setSocketStatus('failed');
    });

    socket.current.on('reconnect', () => {
      socket.current.status = 'connected';
      setSocketStatus('connected');
    });

    socket.current.on('reconnecting', () => {
      socket.current.status = 'reconnecting';
      setSocketStatus('reconnecting');
    });

    socket.current.on('reconnect_failed', () => {
      socket.current.status = 'failed';
      setSocketStatus('failed');
    });
  };

  const getSocket = () => socket.current;

  const connected = () => socketStatus === 'connected';

  const socketValue = useMemo(() => ({
    socket: socketState,
    getSocket,
    socketStatus,
    connected
  }), [socketState, socketStatus]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!isAuthenticated) return;
    // connect
    connectSocket();

    // eslint-disable-next-line consistent-return
    return () => {
      if (socket.current?.readyState) socket.current.close();
    };
  }, [isAuthenticated]);

  return <SocketContext.Provider
    value={{
      ...socketValue
    }}
  >
    {children}
  </SocketContext.Provider >
}

export default SocketProvider;

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired
};