import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@crema/components/AppTable/TableHeader';
import IntlMessages from '@crema/helpers/IntlMessages';

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.licensePlate' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.device' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.camera' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.quantity' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.price' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.amount' /></TableCell>
      <TableCell align='left'><IntlMessages id='record.table.createdAt' /></TableCell>
      <TableCell align='left'></TableCell>
    </TableHeader>
  );
};

export default TableHeading;
