import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { isRequestSuccessful, sanitizeData } from '@crema/helpers/ApiHelper';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const useGetDataApi = (
  url,
  initialData = undefined,
  params = {},
  initialCall = true,
  callbackFun,
) => {
  const [initialUrl, setInitialUrl] = useState(url);
  const [allowApiCall, setAllowApiCall] = useState(initialCall);
  const [loading, setLoading] = useState(initialCall);
  const [apiData, setData] = useState(initialData);
  const [queryParams, updateQueryParams] = useState(params);
  const resStateRef = useRef(false);
  const didCancelRef = useRef(false);

  const updateInitialUrl = (value) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams) => {
    setLoading(true);
    updateQueryParams({ ...queryParams });
    setAllowApiCall(true);
  };

  useEffect(() => {
    didCancelRef.current = false;
    const fetchData = () => {
      resStateRef.current = true;
      let params = {};
      if (!_.isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }
      jwtAxios
        .get(initialUrl, { params: sanitizeData(params) })
        .then((data) => {
          console.log(
            initialUrl,
            data.data,
            didCancelRef.current,
            isRequestSuccessful(data.status),
          );
          resStateRef.current = false;
          if (!didCancelRef.current) {
            if (isRequestSuccessful(data.status)) {
              setLoading(false);
              setData(data.data);
              if (callbackFun) callbackFun(data.data);
            } else {
              setLoading(false);
              console.log('Error', data.data);
              setData(initialData);
              if (callbackFun) callbackFun(data.data);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            console.log(initialUrl, error.response.data.message);
            if (callbackFun) callbackFun(error.response.data);
          } else {
            if (callbackFun) callbackFun(error);
          }
          setLoading(false);
        });
    };
    if (allowApiCall && !resStateRef.current) fetchData();
    return () => {
      didCancelRef.current = true;
    };
  }, [initialUrl, queryParams, allowApiCall]);
  return [
    {
      loading,
      apiData,
      initialUrl,
    },
    {
      setData,
      setLoading,
      updateInitialUrl,
      setQueryParams,
      reCallAPI,
    },
  ];
};

export const trimObjectValues = (obj) => {
  if (_.isEmpty(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  });
  return obj;
};

const handleApiResponse = (url, data, resolve, reject) => {
  if (isRequestSuccessful(data.status)) {
    return resolve(data.data);
  } else {
    return reject(data.data);
  }
};

const handleAPIError = (url, error, reject) => {
  if (error?.response?.data?.message) {
    return reject(error.response.data);
  } else {
    return reject(error);
  }
};

export const postDataApi = (
  url,
  payload,
  headers,
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, sanitizeData(payload), headers ? { headers } : {})
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const putDataApi = (
  url,
  payload
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .put(url, sanitizeData(payload))
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const getDataApi = (
  url,
  params = {},
  headers,
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .get(url, { params: sanitizeData(params), headers })
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const deleteDataApi = (
  url,
  params = {},
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .delete(url, { params })
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadDataApi = (
  url,
  payload = {},
  onUploadProgress = () => {
    console.log('');
  },
  allowDownload = false,
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, payload, {
        onUploadProgress,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        responseType: allowDownload ? 'arraybuffer' : 'stream',
      })
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadPutDataApi = (
  url,
  payload = {}
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .put(url, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadPostDataApi = (
  url,
  payload = {},
) => {
  // 

  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((data) => {
        return handleApiResponse(url, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, error, reject);
      });
    return Promise.resolve();
  });
};
