import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { ccyFormat } from '@crema/helpers/Common';
import IntlMessages from '@crema/helpers/IntlMessages';
import { useIntl } from 'react-intl';

const MixBarChart = ({ data }) => {
  const { messages } = useIntl();
  if (data)
    data = [...data].sort((a, b) => a.name - b.name)
  return (
    <ResponsiveContainer width='100%' height={270}>
      <BarChart
        barSize={7}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis dataKey='name' dy={10} name="Tháng" tickFormatter={(value) => { return (messages['dashboard.salesState.chart.month.label'] ?? "dashboard.salesState.chart.month.label") + " " + value }} />
        <Tooltip formatter={(value) => ccyFormat(value)} />
        <YAxis type="number" dataKey="count" tickFormatter={(value) => ccyFormat(value)} />
        <Legend />
        <Bar dataKey='count' name={messages['dashboard.salesState.chart.count'] ?? "dashboard.salesState.chart.count"} stackId='a' fill='#49BD65' radius={[10, 10, 0, 0]} />
        <Bar dataKey='quantity' name={messages['dashboard.salesState.chart.quantity'] ?? "dashboard.salesState.chart.quantity"} stackId='b' fill='#0A8FDC' radius={[10, 10, 0, 0]} />
        <Bar dataKey='amount' name={messages['dashboard.salesState.chart.amount'] ?? "dashboard.salesState.chart.amount"} stackId='c' fill='#f44d50' radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MixBarChart;

MixBarChart.defaultProps = {
  data: [],
};

MixBarChart.propTypes = {
  data: PropTypes.array,
};
