import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IntlMessages from '@crema/helpers/IntlMessages';
import AppConfirmDialog from '@crema/components/AppConfirmDialog';
import { useAuthUser } from '@crema/hooks/AuthHooks';

const OrderActions = ({ id, onDelete, onViewRecord }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { isCompanyAdmin } = useAuthUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  }

  return (
    <Box>
      <IconButton
        aria-controls='alpha-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='alpha-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          style={{ fontSize: 14 }}
          onClick={() => onViewRecord()}
        >
          <IntlMessages id='table.action.edit' />
        </MenuItem>
        {isCompanyAdmin && (
          <MenuItem style={{ fontSize: 14 }} onClick={handleDelete}>
            <IntlMessages id='table.action.delete' />
          </MenuItem>
        )}
      </Menu>
      <AppConfirmDialog
        open={isDeleteDialogOpen}
        onDeny={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          onDelete(id);
          setDeleteDialogOpen(false);
        }}
        title={<IntlMessages id='table.action.delete' />}
        dialogTitle={<IntlMessages id='common.deleteItem' />}
      />
    </Box>
  );
};
export default OrderActions;

OrderActions.propTypes = {
  id: PropTypes.string,
  onDelete: PropTypes.func,
  onViewRecord: PropTypes.func
};
