import React from 'react';
import { Navigate } from 'react-router-dom';

import { authRouteConfig } from './AuthRoutes';
import Error403 from '../../../modules/errorPages/Error403';
import { errorPagesConfigs } from './ErrorPagesRoutes';
import { accountPagesConfigs } from './AccountRoutes';
import { samplePagesConfigs } from './SamplePages';
import { companyPagesConfigs } from './Company';
import { userPagesConfigs } from './UserRoutes';
import { categoryPagesConfigs } from './CategoryRoutes';
import { driverPagesConfigs } from './DriverRoutes';
import { vehiclePagesConfigs } from './VehicleRoutes';
import { placePagesConfigs } from './PlaceRoutes';
import { tripPagesConfigs } from './TripRoutes';

export const authorizedStructure = (loginUrl) => {
  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: [...samplePagesConfigs, ...accountPagesConfigs, ...companyPagesConfigs, ...userPagesConfigs, ...categoryPagesConfigs, ...driverPagesConfigs, ...vehiclePagesConfigs, ...placePagesConfigs, ...tripPagesConfigs],
  };
};

export const publicStructure = (initialUrl) => {
  return {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
  };
};
export const anonymousStructure = (initialUrl) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to='/error-pages/error-404' />,
      },
    ]),
  };
};
