import React, { useMemo } from 'react';
import { InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from 'prop-types';
import { useAuthUser } from '@crema/hooks/AuthHooks';

function SelectOption({ label, options, onChange, defaultValue, multiple = false }) {
  const { user } = useAuthUser();
  const { role: userRole } = user;

  const permittedOptions = useMemo(() => {
    return options.filter(opt => {
      if (opt.permittedRole) {
        if (Array.isArray(opt.permittedRole)) {
          const hasRole = userRole.find(role => opt.permittedRole.includes(role));
          return !!hasRole;
        } else {
          const hasRole = userRole.find(role => opt.permittedRole.split(',').includes(role));
          return !!hasRole
        }
      }
      return true;
    })
  }, [options])

  return (
    <>
      {
        label && (
          <InputLabel
            id='demo-simple-select-outlined-label'
          >
            {label}
          </InputLabel>
        )
      }
      <Select
        label={label}
        value={defaultValue}
        onChange={(event) =>
          onChange(event.target.value)
        }
        multiple={multiple}
      >
        <MenuItem value="">
          <em>{label}</em>
        </MenuItem>
        {permittedOptions.map(opt => {
          return (
            <MenuItem
              value={opt.value}
              key={opt?.key || opt.value}
              sx={{
                cursor: 'pointer',
                inputVariant: 'outlined',
              }}
            >
              {opt?.label}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}

SelectOption.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  multiple: PropTypes.bool
};

export default SelectOption;