import React from 'react';
import IntlMessages from '@crema/helpers/IntlMessages';
import Box from '@mui/material/Box';
import { Form } from 'formik';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import AppGridContainer from '@crema/components/AppGridContainer';
import { styled } from '@mui/material/styles';
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import CategorySelect from 'component/category/CategorySelect';
import AppTextField from '@crema/components/AppFormComponents/AppTextField';
import { Fonts } from '@crema/constants/AppEnums';

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 20,
  [theme.breakpoints.up('xl')]: {
    marginTop: 32,
    marginBottom: 32,
  },
}));

const AddRecordForm = (props) => {
  const { values, setFieldValue, isSubmitting, errors, getInvoice } = props;
  const inputRef = React.useRef();

  const onPriceOrQuantityChange = (field, value) => {
    setFieldValue(field, value);
    switch (field) {
      case 'quantity':
        setFieldValue('amount', values.price * value);
        break;
      case 'price':
        setFieldValue('amount', value * values.quantity);
        break;
    }
  }

  const onProductTypeChange = (object) => {
    setFieldValue('productType', object?._id);
    if (object?.defaultUnit) {
      setFieldValue('unit', object.defaultUnit);
    }
    if (object?.defaultPrice) {
      setFieldValue('price', object.defaultPrice);
      inputRef.current.focus();
    }
  }

  return (
    <Form
      style={{
        width: '100%',
      }}
      noValidate
      autoComplete='off'
    >
      <div>
        <Box mb={5}>
          <AppGridContainer spacing={5}>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl
                sx={{
                  width: '100%',
                }}
                variant='outlined'
                error={errors.productType}
              >
                <CategorySelect fullResult label={<IntlMessages id='common.selectProductType' />} group='product-type' onChange={(value) => onProductTypeChange(value)} defaultValue={values.productType} />
                {errors.productType && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.productTypeRequired' /></FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                sx={{
                  width: '100%',
                }}
                variant='outlined'
                error={errors.unit}
              >
                <CategorySelect label={<IntlMessages id='common.selectUnit' />} group='unit' onChange={(value) => setFieldValue('unit', value)} defaultValue={values.unit} />
                {errors.unit && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.unitRequired' /></FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <NumericFormat
                value={values.quantity || 0}
                thousandSeparator=","
                customInput={TextField} label={<IntlMessages id='record.quantity' />}
                onValueChange={(val) => onPriceOrQuantityChange('quantity', val.value || 0)}
                style={{ width: '100%' }}
                error={errors.quantity} inputRef={inputRef} onFocus={e => e.target.select()}
              />
              {errors.quantity && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.quantityRequired' /></FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <NumericFormat
                value={values.price || 0}
                thousandSeparator=","
                customInput={TextField} label={<IntlMessages id='record.price' />}
                onValueChange={(val) => onPriceOrQuantityChange('price', val.value || 0)}
                // suffix=' VND'
                style={{ width: '100%' }}
                error={errors.price}
              />
              {errors.price && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.priceRequired' /></FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <NumericFormat
                value={values.amount || 0}
                thousandSeparator=","
                customInput={TextField}
                label={<IntlMessages id='record.amount' />}
                onValueChange={(val) => setFieldValue('amount', val.value || 0)}
                // suffix=' VND'
                style={{ width: '100%' }}
                error={errors.amount}
                disabled={true}
              />
              {errors.amount && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.amountRequired' /></FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <AppTextField
                sx={{
                  width: '100%',
                  fontWeight: Fonts.LIGHT,
                }}
                variant='outlined'
                label={<IntlMessages id='record.note' />}
                name='note'
              />
              {errors.note && <FormHelperText style={{ color: '#d32f2f' }}><IntlMessages id='record.noteRequired' /></FormHelperText>}
            </Grid>
          </AppGridContainer>
        </Box>
        <StyledDivider />
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button
          sx={{
            position: 'relative',
            minWidth: 100,
            mr: 1
          }}
          color='success'
          variant='outlined'
          disabled={isSubmitting}
          type='button'
          onClick={() => getInvoice()}
        >
          <IntlMessages id='common.invoice' />
        </Button>
        <Button
          sx={{
            position: 'relative',
            minWidth: 100,
          }}
          color='primary'
          variant='outlined'
          disabled={isSubmitting}
          type='submit'
        >
          <IntlMessages id='common.save' />
        </Button>
      </div>
    </Form>
  );
};

export default AddRecordForm;

AddRecordForm.defaultProps = {
  isSubmitting: false,
};

AddRecordForm.propTypes = {
  values: PropTypes.object.isRequired,
  recordLabels: PropTypes.array.isRequired,
  setRecordLabels: PropTypes.func,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
  record: PropTypes.object,
  errors: PropTypes.any,
  getInvoice: PropTypes.func
};
