import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { Box, Chip, ListItem, Menu, MenuItem, Typography } from '@mui/material';
import { Fonts } from '@crema/constants/AppEnums';
import renderHTML from 'react-render-html';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR } from '@crema/constants/ActionTypes';
import {
  onDeleteNotification,
  onReadNotification,
} from 'toolkit/actions/Notification';
import IntlMessages from '@crema/helpers/IntlMessages';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BiBell } from 'react-icons/bi';
import { getFormattedDate } from '@crema/helpers/DateHelper';
const NotificationItem = (props) => {
  const { item, onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateNoti = () => {
    if (item.link && item.link !== '#') {
      dispatch(onReadNotification(item.id));
      onClose(true);
      navigate(item.link);
    } else {
      // dispatch({
      //   type: FETCH_ERROR,
      //   payload: 'Không có đường dẫn cho thông báo này',
      // });
    }
  };

  const deleteNoti = (e, id) => {
    e.stopPropagation();
    handleClose();
    dispatch(onDeleteNotification(id));
  };

  const readOrUnread = (id) => {
    handleClose();
    dispatch(onReadNotification(id));
  };

  return (
    <>
      <ListItem
        sx={{
          padding: '8px 20px',
          pr: 0,
          cursor: 'pointer',
          background: item.unreadNotification == 1 ? 'rgb(244 247 254)' : '#fff',
          '&:hover': {
            backgroundColor: '#cccccc4d',
            '.MuiSvgIcon-root': {
              opacity: 1,
            },
          },
        }}
        className='item-hover'
        onClick={navigateNoti}
      >
        <ListItemAvatar
          sx={{
            minWidth: 0,
            mr: 4,
          }}
        >
          {item.image != "" ? (<Avatar
            sx={{
              width: 46,
              height: 46,
            }}
            alt='notification_img'
            src={item.image}
          />) : (
            <Box
              component='span'
              sx={{
                mr: 3.5,
                height: 46,
                width: 46,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color: '#0A8FDC',
                backgroundColor: '#0A8FDC' + '22'
              }}
            >

              <BiBell />
            </Box>)}
        </ListItemAvatar>
        <Box
          sx={{
            flex: 1,
            fontSize: 14,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <Typography>
            <Box
              component='span'
              sx={{
                fontSize: 14,
                fontWeight: item.read ? Fonts.LIGHT : Fonts.MEDIUM,
                mb: 0.5,
                color: (theme) => theme.palette.text.primary,
                mr: 1,
              }}
            >
              {renderHTML(item.title)}
            </Box>
            <Box
              sx={{
                fontSize: 13,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '20px',
                WebkitLineClamp: 3,
                maxHeight: '60px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {renderHTML(item.content)}
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: '6px' }}
            >
              <Chip
                label={
                  <IntlMessages id={`notification.module.${item.module}`} />
                }
                color={item.type != "" ? item.type : 'info'}
                sx={{
                  height: '22px',
                  fontSize: '12px',
                  '& span': {
                    paddingBottom: '2px !important',
                    color: '#fff',
                  },
                }}
              />
              <Box
                sx={{
                  fontSize: 12,
                  fontStyle: 'italic',
                }}
              >
                {getFormattedDate(item.dateTime, 'DD/MM/YYYY HH:mm:ss')}
              </Box>
            </Box>
          </Typography>
        </Box>
        <Box sx={{ pr: '8px' }}>
          <MoreVertIcon
            sx={{
              color: '#868686',
              opacity: matchesXS ? 1 : 0,
              transition: 'opacity 0.3s',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          />
        </Box>
      </ListItem>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='custom-notification'
      >
        <MenuItem
          onClick={(e) => {
            readOrUnread(item.id);
          }}
        >
          <IntlMessages
            id={item.unreadNotification != 1 ? 'mailApp.markAsUnread' : 'mailApp.markAsRead'}
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            deleteNoti(e, item.id);
          }}
        >
          <IntlMessages id='common.delete' />
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
