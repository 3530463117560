import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Fonts } from '@crema/constants/AppEnums';
import { useIntl } from 'react-intl';
import AppCard from '@crema/components/AppCard';
import RecordHourGraph from './RecordHourGraph';
import Link from '@mui/material/Link';
import { green, red } from '@mui/material/colors';
import IntlMessages from '@crema/helpers/IntlMessages';
import { ccyFormat, ccyFormatPercent } from '@crema/helpers/Common';

const RecordHour = ({ data }) => {
    const { messages } = useIntl();
    return (
        <AppCard contentStyle={{ padding: 0 }}>
            <Box
                sx={{
                    pt: { xs: 5, sm: 5, xl: 5 },
                }}
            >
                <Box
                    sx={{
                        px: 6,
                    }}
                >
                    <Box
                        sx={{
                            color: '#49bd65',
                            mb: 1,
                            fontWeight: Fonts.BOLD,
                            fontSize: 16,
                        }}
                        component='h3'
                    >
                        <IntlMessages id='dashboard.recordHour.title' />
                    </Box>
                    <Box
                        sx={{
                            color: '#49bd65AA',
                            mb: 4,
                        }}
                    >
                        <IntlMessages id='dashboard.recordHour.subTitle' />
                    </Box>
                </Box>
                <Box
                    sx={{
                        mt: 'auto',
                    }}
                >
                    <RecordHourGraph data={data.data} />
                </Box>
            </Box>
            <Box
                sx={{
                    pt: 5,
                    pb: 1,
                    px: 6,
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            mb: 0.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                fontWeight: Fonts.MEDIUM,
                                fontSize: 18,
                            }}
                            component='h3'
                        >
                            {ccyFormat(data.total)}
                        </Box>
                        <Box
                            sx={{
                                ml: 3,
                                fontSize: 16,
                                fontWeight: Fonts.MEDIUM,
                                color: data.growth > 0.0 ? green[500] : red[500],
                            }}
                            component='span'
                        >
                            {data.percent ? ccyFormatPercent(data?.percent + "") : "0"}% <IntlMessages id='dashboard.recordHour.percent' />
                        </Box>
                    </Box>
                    <Box
                        component='p'
                        sx={{
                            fontSize: 14,
                            color: 'text.secondary',
                            mb: 1,
                        }}
                    >
                        {messages['dashboard.RecordHour']}
                    </Box>
                </Box>
            </Box>
        </AppCard>
    );
};

export default RecordHour;

RecordHour.defaultProps = {};

RecordHour.propTypes = {
    data: PropTypes.object,
};
