import { useAuthUser } from "@crema/hooks/AuthHooks";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetRecordStats, onGetDashBoardRecordHour, onGetDashBoardSaleYear, onGetDashBoardSaleMonth } from "toolkit/actions";
import AdminDashboard from "./AdminDashboard";
import AppInfoView from "@crema/components/AppInfoView";
import Event from "socket/Event";
const Dashboard = () => {
  const { user } = useAuthUser();
  const { roles } = user;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetRecordStats());
    dispatch(onGetDashBoardRecordHour());
    dispatch(onGetDashBoardSaleYear());
    dispatch(onGetDashBoardSaleMonth());
  }, [dispatch]);

  const onGetNewRecord = () => {
    dispatch(onGetRecordStats());
    dispatch(onGetDashBoardRecordHour());
    dispatch(onGetDashBoardSaleYear());
    dispatch(onGetDashBoardSaleMonth());
  }

  const stats = useSelector(({ record }) => record.stats);

  return <>

    <Event event='new_record' handler={() => onGetNewRecord()} />
    {roles.includes('admin') && (
      <Box>Dashboard admin</Box>
    )}

    {roles.includes('company-admin') && stats && (
      <AdminDashboard stats={stats} />
    )}
    {roles.includes('dealer') && (
      <Box>Dashboard dealer</Box>
    )}
    <AppInfoView />
  </>
}

export default Dashboard;