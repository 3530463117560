import {
  DELETE_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATION,
  LOADING_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  RESET_NOTIFICATION,
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  total: 0,
  loading: false,
};

const NotificationReducer = createReducer(initialState, (builder) => {
  builder.addCase(RESET_NOTIFICATION, (state, action) => {
    state.notifications = [];
  });
  builder.addCase(GET_NOTIFICATION, (state, action) => {
    const newNotifications = action.payload.items.filter(item => {
      return !state.notifications.some(notification => notification.id === item.id);
    });

    state.notifications = [...state.notifications, ...newNotifications];

    state.total = action.payload.total;
  });
  builder.addCase(LOADING_NOTIFICATION, (state, action) => {
    state.loading = action.payload;
  });
  builder.addCase(READ_NOTIFICATION, (state, action) => {
    state.notifications = state.notifications.map((notification) =>
      notification.id == action.payload.item.id
        ? { ...notification, unreadNotification: action.payload.item.unreadNotification }
        : notification,
    );
  });
  builder.addCase(DELETE_NOTIFICATION, (state, action) => {
    state.notifications = state.notifications.filter(
      (notification) =>
        notification.id.toString() !== action.payload.item.id.toString(),
    );
    state.total = state.total - 1;
    state.loading = false;
  });
  builder.addCase(READ_ALL_NOTIFICATION, (state, action) => {
    state.notifications = state.notifications.map((notification) => ({
      ...notification,
      unreadNotification: 0,
    }));
    state.loading = false;
  });
  builder.addCase(DELETE_ALL_NOTIFICATION, (state, action) => {
    state.notifications = []
    state.total = 0;
    state.loading = false;
  });
});

export default NotificationReducer;
