import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_RECORD_DETAIL,
  GET_RECORD_LIST,
  GET_RECORD_STATS,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_RECORD
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';
import { saveAs } from 'file-saver';
import moment from 'moment';

export const onGetRecordList = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    jwtAxios
      .get('records', {
        params: {
          ...payload,
          sort: 'dateTime',
          sortType: 'desc'
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_RECORD_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteSelectedRecords = (recordIds = [], companyId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await recordIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`records/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetRecordList({ name: '', companyId }))
  };
};

export const onGetRecordDetail = (recordId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`records/${recordId}`)).data;
      dispatch({ type: GET_RECORD_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateRecord = (recordId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`records/${recordId}`, payload)).data;
      dispatch({ type: GET_RECORD_DETAIL, payload: data });
      dispatch({ type: UPDATED_RECORD, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['record.created'],
      });
      dispatch(showMessage(messages['record.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateRecordLicencePlate = (recordId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`records/${recordId}/license-plate`, payload)).data;
      dispatch({ type: GET_RECORD_DETAIL, payload: data });
      dispatch({ type: UPDATED_RECORD, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['record.created'],
      });
      dispatch(showMessage(messages['record.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onGetRecordStats = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('record-stats', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_RECORD_STATS, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onExportExcel = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('record-export', {
        params: {
          ...payload
        },
        responseType: 'blob',
      })
      .then((resp) => {
        const { data } = resp;
        saveAs(data, 'Data-' + moment().format('DD-MM-yyyy-HH-mm-ss'));
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }
}

export const onExportExcelGeneral = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('record-export-general', {
        params: {
          ...payload
        },
        responseType: 'blob',
      })
      .then((resp) => {
        const { data } = resp;
        saveAs(data, 'General-' + moment().format('DD-MM-yyyy-HH-mm-ss'));
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }
}