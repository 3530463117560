import {
  CREATE_NEW_TRIP,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TRIP_DETAIL,
  GET_TRIP_LIST,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_TRIP
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';
import { useNavigate } from 'react-router-dom';

export const onGetTripList = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('cargo-trips', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_TRIP_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateTrip = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('cargo-trips', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_TRIP, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['trip.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });
  };
};

export const onDeleteSelectedTrips = (tripIds = [], group) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await tripIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`cargo-trips/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetTripList({ name: '', group }))
  };
};

export const onGetTripDetail = (tripId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`cargo-trips/${tripId}`)).data;
      dispatch({ type: GET_TRIP_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateTrip = (tripId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`cargo-trips/${tripId}`, payload)).data;
      dispatch({ type: GET_TRIP_DETAIL, payload: data });
      dispatch({ type: UPDATED_TRIP, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['trip.created'],
      });
      dispatch(showMessage(messages['trip.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};