import React from 'react';
import { Box } from '@mui/material';
import { useThemeContext } from '@crema/context/AppContextProvider/ThemeContextProvider';
import { alpha } from '@mui/material/styles';
import { ReactComponent as Logo } from '../../../../../assets/icon/logo.svg';
import { ReactComponent as LogoText } from '../../../../../assets/icon/logo_text.svg';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import orange from '@mui/material/colors/orange';

import Avatar from '@mui/material/Avatar';
const AppLogo = () => {
  const { user } = useAuthUser();
  const companyNameFirstChar = user?.companyName && user.companyName != "" ? user.companyName.charAt(0).toUpperCase() : false;

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: { xs: 40, sm: 45 },
        },
      }}
      className='app-logo'
    >
      <Box sx={{ py: 0.5 }}>
        {user?.logoCompany ? (
          <Avatar
            sx={{
              height: 40,
              width: 40,
              fontSize: 24,
              backgroundColor: orange[500],
            }}
            src={user.logoCompany}
          />
        ) : (

          companyNameFirstChar ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500],
              }}
            >
              {companyNameFirstChar}
            </Avatar>
          ) : <></>

        )}
      </Box>
    </Box >
  );
};

export default AppLogo;
