import {
  DELETE_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  FETCH_ERROR,
  GET_NOTIFICATION,
  LOADING_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  SHOW_MESSAGE,
} from '@crema/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const onGetNotification = (page) => {
  return (dispatch) => {
    dispatch({ type: LOADING_NOTIFICATION, payload: true });
    jwtAxios
      .get('/notifications', {
        params: {
          page: page,
          take: 10,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: LOADING_NOTIFICATION, payload: false });
          dispatch({ type: GET_NOTIFICATION, payload: data.data.data });
        } else {
          dispatch({ type: LOADING_NOTIFICATION, payload: false });
          dispatch({
            type: FETCH_ERROR,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_NOTIFICATION, payload: false });
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error.message,
        });
      });
  };
};

export const onReadNotification = (id) => {
  return (dispatch) => {
    jwtAxios
      .post(`/notification/read/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: READ_NOTIFICATION, payload: data.data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error.message,
        });
      });
  };
};

export const onReadAllNotification = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_NOTIFICATION, payload: true });
    jwtAxios
      .post(`/notification/read-all`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: READ_ALL_NOTIFICATION, payload: data.data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error.message,
        });
      });
  };
};

export const onDeleteNotification = (id) => {
  return (dispatch) => {
    dispatch({ type: LOADING_NOTIFICATION, payload: true });
    jwtAxios
      .delete(`/notification/remove/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: DELETE_NOTIFICATION, payload: data.data.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message,
          });
        } else {
          dispatch({ type: LOADING_NOTIFICATION, payload: false });
          dispatch({
            type: FETCH_ERROR,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_NOTIFICATION, payload: false });
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error.message,
        });
      });
  };
};

export const onDeleteAllNotification = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_NOTIFICATION, payload: true });
    jwtAxios
      .delete(`/notification/remove-all`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: DELETE_ALL_NOTIFICATION, payload: data.data.data });
        } else {
          dispatch({ type: LOADING_NOTIFICATION, payload: false });
          dispatch({
            type: FETCH_ERROR,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_NOTIFICATION, payload: false });
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error.message,
        });
      });
  };
};