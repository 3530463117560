import { RoutePermittedRole } from '@crema/constants/AppEnums';
import React from 'react';
import { BiHome, BiUser, BiUserCircle, BiSolidDashboard, BiAnalyse, BiCameraMovie, BiCamera, BiCameraHome, BiCategory, BiUnite, BiCar, BiSolidTruck } from 'react-icons/bi';

const routesConfig = [
  {
    id: 'account',
    title: 'Account',
    messageId: 'sidebar.account',
    type: 'item',
    icon: <BiUserCircle />,
    url: '/my-profile',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'item',
    icon: <BiSolidDashboard />,
    url: '/dashboard/',
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.Dealer, RoutePermittedRole.Admin, RoutePermittedRole.User],
  },
  {
    id: 'my-company-device',
    title: 'View Device',
    messageId: 'sidebar.viewDevice',
    type: 'item',
    icon: <BiCameraHome />,
    url: '/view-device/',
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
  },
  {
    id: 'my-company-report',
    title: 'Report',
    messageId: 'sidebar.report',
    type: 'item',
    icon: <BiAnalyse />,
    url: '/report/',
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
  },
  {
    id: 'my-company-product-type',
    title: 'Product Type',
    messageId: 'sidebar.productType',
    type: 'group',
    icon: <BiCategory />,
    url: '/category/product-type/list',
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
    children: [{
      id: 'my-company-product-type',
      title: 'Product Type',
      messageId: 'sidebar.productType',
      type: 'item',
      icon: <BiCategory />,
      url: '/category/product-type/list',
      permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
    }, {
      id: 'my-company-unit',
      title: 'Unit',
      messageId: 'sidebar.unit',
      type: 'item',
      icon: <BiCategory />,
      url: '/category/unit/list',
      permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
    },
    ]
  },
  {
    id: 'driver',
    title: 'Driver',
    messageId: 'sidebar.driver',
    type: 'item',
    icon: <BiUser />,
    url: '/drivers/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'vehicle',
    title: 'Vehicle',
    messageId: 'sidebar.vehicle',
    type: 'item',
    icon: <BiCar />,
    url: '/vehicles/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'place',
    title: 'Place',
    messageId: 'sidebar.place',
    type: 'item',
    icon: <BiHome />,
    url: '/places/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'trip',
    title: 'Trip',
    messageId: 'sidebar.trip',
    type: 'item',
    icon: <BiSolidTruck />,
    url: '/trips/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'trip-report',
    title: 'Trip Report',
    messageId: 'sidebar.tripReport',
    type: 'item',
    icon: <BiAnalyse />,
    url: '/trip-report/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'my-company',
    title: 'My Company',
    messageId: 'sidebar.my-company',
    type: 'item',
    icon: <BiHome />,
    url: '/my-company/',
    permittedRole: [RoutePermittedRole.CompanyAdmin],
  },
  {
    id: 'company',
    title: 'Listing',
    messageId: 'sidebar.company.list',
    type: 'item',
    icon: <BiHome />,
    url: '/company/',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer],
  },
  {
    id: 'user',
    title: 'User Listing',
    messageId: 'sidebar.user.list',
    type: 'item',
    icon: <BiUser />,
    url: '/user/',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer, RoutePermittedRole.CompanyAdmin],
  },
];
export default routesConfig;
