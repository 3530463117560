import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@crema/helpers/Common';

const StatGraphs = ({ data }) => {

  if (data)
    data = [...data].sort((a, b) => a.name - b.name)
  const { messages } = useIntl();
  return (
    <ResponsiveContainer width='100%' height={350}>
      <LineChart data={data}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey='name'
          tickLine={false}
          axisLine={false}
          padding={{ left: 20, right: 20 }}
          tickFormatter={(value) => { return (messages['dashboard.salesState.chart.day.label'] ?? "dashboard.salesState.chart.day.label") + " " + value }}
        />
        {/* <YAxis padding={{ left: 10 }} type="number" tickFormatter={(value) => ccyFormat(value)} /> */}
        <Tooltip labelStyle={{ color: 'black' }} formatter={(value) => ccyFormat(value)} />
        <CartesianGrid stroke='#eee' horizontal={true} vertical={false} strokeDasharray="3 3" />
        <Line
          type='monotone'
          dataKey='count'
          stroke='#49BD65'
          dot={false}
          strokeWidth={2}
          activeDot={{ r: 4 }}
          name={messages['dashboard.salesState.chart.count'] ?? "dashboard.salesState.chart.count"}
        />
        <Line
          type='monotone'
          dot={false}
          strokeWidth={2}
          dataKey='quantity'
          stroke='#0A8FDC'
          name={messages['dashboard.salesState.chart.quantity'] ?? "dashboard.salesState.chart.quantity"}
        />
        <Line
          type='monotone'
          dot={false}
          strokeWidth={2}
          dataKey='amount'
          stroke='#f44d50'
          name={messages['dashboard.salesState.chart.amount'] ?? "dashboard.salesState.chart.amount"}
        />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default StatGraphs;

StatGraphs.defaultProps = {
  data: [],
};

StatGraphs.propTypes = {
  data: PropTypes.array,
};
