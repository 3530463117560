// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Embed Responsive Classes*/
.cr-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.cr-embed-responsive:before {
  display: block;
  content: '';
}

.cr-embed-responsive .cr-embed-responsive-item,
.cr-embed-responsive iframe,
.cr-embed-responsive embed,
.cr-embed-responsive object,
.cr-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.cr-embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}

.cr-embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.cr-embed-responsive-4by3:before {
  padding-top: 75%;
}

.cr-embed-responsive-1by1:before {
  padding-top: 100%;
}

@media screen and (max-width: 499px) {
  .cr-embed-responsive-21by9:before,
  .cr-embed-responsive-16by9:before {
    padding-top: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/maps.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;;;;;EAKE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;;IAEE,iBAAiB;EACnB;AACF","sourcesContent":["/*Embed Responsive Classes*/\n.cr-embed-responsive {\n  position: relative;\n  display: block;\n  width: 100%;\n  padding: 0;\n  overflow: hidden;\n}\n\n.cr-embed-responsive:before {\n  display: block;\n  content: '';\n}\n\n.cr-embed-responsive .cr-embed-responsive-item,\n.cr-embed-responsive iframe,\n.cr-embed-responsive embed,\n.cr-embed-responsive object,\n.cr-embed-responsive video {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border: 0;\n}\n\n.cr-embed-responsive-21by9:before {\n  padding-top: 42.85714286%;\n}\n\n.cr-embed-responsive-16by9:before {\n  padding-top: 56.25%;\n}\n\n.cr-embed-responsive-4by3:before {\n  padding-top: 75%;\n}\n\n.cr-embed-responsive-1by1:before {\n  padding-top: 100%;\n}\n\n@media screen and (max-width: 499px) {\n  .cr-embed-responsive-21by9:before,\n  .cr-embed-responsive-16by9:before {\n    padding-top: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
