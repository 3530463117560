import {
  CREATE_NEW_CAMERA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_CAMERA_LIST,
  GET_CAMERA_DETAIL,
  GET_CAMERA_LIST,
  GET_DASHBOARD_CAMERAS,
  SET_DASHBOARD_CAMERAS,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_CAMERA
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';

export const onGetCameraList = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('cameras', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_CAMERA_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateCamera = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('cameras', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_CAMERA, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['camera.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });
  };
};

export const onDeleteSelectedCameras = (cameraIds = [], deviceId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await cameraIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`cameras/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetCameraList({ name: '', deviceId }))
  };
};

export const onGetCameraDetail = (cameraId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`cameras/${cameraId}/view`)).data;
      dispatch({ type: GET_CAMERA_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateCamera = (cameraId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`cameras/${cameraId}`, payload)).data;
      dispatch({ type: GET_CAMERA_DETAIL, payload: data });
      dispatch({ type: UPDATED_CAMERA, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['camera.created'],
      });
      dispatch(showMessage(messages['camera.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onSetSelectedCamera = (payload) => {
  return (dispatch) => {
    dispatch({ type: GET_CAMERA_DETAIL, payload });
  };
}

export const onGetAllCameras = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('cameras', {
        params: {
          ...payload,
          take: 1000
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_ALL_CAMERA_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDashBoardCameras = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('cameras', {
        params: {
          ...payload,
          take: 1000
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DASHBOARD_CAMERAS, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSetDashBoardCameras = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_DASHBOARD_CAMERAS, payload });
  };
};