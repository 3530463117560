import {
  CREATE_NEW_VEHICLE,
  GET_VEHICLE_DETAIL,
  GET_VEHICLE_LIST,
  UPDATED_VEHICLE
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedVehicle: null
};

const vehicleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_VEHICLE_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_VEHICLE, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_VEHICLE_DETAIL, (state, action) => {
      state.selectedVehicle = action.payload;
    })
    .addCase(UPDATED_VEHICLE, (state, action) => {
      const selectedVehicle = action.payload;
      state.selectedVehicle = selectedVehicle;
      const index = state.items.findIndex(item => item._id === selectedVehicle._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedVehicle;
        state.items = nextList;
      }
    })
});

export default vehicleReducer;
