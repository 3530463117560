export const getCity = async () => {
  return (dispatch) => {
    // TO_DO get city from api
  }
}

export const getDistrict = async (city) => {
  return (dispatch) => {
    // TO_DO get district from api
  }
}

export const getWard = async (district) => {
  return (dispatch) => {
    // TO_DO get ward from api
  }
}

