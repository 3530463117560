import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwtAxios, { setAuthToken } from './index';
import { getError } from '@crema/helpers/ApiHelper';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({
  children,
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage
}) => {
  const [userData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  useEffect(() => {
    const getAuthUser = () => {
      fetchStart();
      const token = localStorage.getItem('token');

      if (!token) {
        fetchSuccess();
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('users/me')
        .then(({ data }) => {
          fetchSuccess();
          setJWTAuthData({
            user: data.data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() => {
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          });
          fetchSuccess();
        });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ email, password }) => {
    fetchStart();
    try {
      const { data: respLogin } = (await jwtAxios.post('auth/login', { email, password })).data;
      localStorage.setItem('token', respLogin.token);
      setAuthToken(respLogin.token);
      const { data } = await jwtAxios.get('users/me');
      setJWTAuthData({
        user: data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      fetchSuccess();
    } catch (error) {
      setJWTAuthData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchError(getError(error.response || error.message));
    }
  };

  const signUpUser = async ({ name, email, password, role }) => {
    fetchStart();
    try {
      const { data } = (await jwtAxios.post('auth/register', { name, email, password, role })).data;
      fetchSuccess();
      showMessage(data?.message || 'Request successfully');
    } catch (error) {
      setJWTAuthData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchError(getError(error.response || error.message));
    }
  };

  const forgetPassword = async ({ email }) => {
    fetchStart();
    try {
      const { data } = (await jwtAxios.post('auth/forgot', { email })).data;
      fetchSuccess();
      showMessage(data?.message || 'Request successfully');
    } catch (error) {
      setJWTAuthData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchError(getError(error.response || error.message));
    }
  }

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...userData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          forgetPassword
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchStart: PropTypes.func,
  fetchSuccess: PropTypes.func,
  fetchError: PropTypes.func,
  showMessage: PropTypes.func
};
