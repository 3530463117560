import {
  CREATE_NEW_USER,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_LIST,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_USER
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';

export const onGetUserList = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('users/search', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_USER_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateUser = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('users', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_USER, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['user.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteSelectedUsers = (userIds = []) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await userIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`users/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetUserList({ name: '' }))
  };
};

export const onGetUserDetail = (userId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`users/${userId}/view`)).data;
      dispatch({ type: GET_USER_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateUser = (userId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`users/${userId}`, payload)).data;
      dispatch({ type: GET_USER_DETAIL, payload: data });
      dispatch({ type: UPDATED_USER, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch(showMessage(messages['user.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};