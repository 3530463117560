import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Fonts } from '@crema/constants/AppEnums';
import AppGridContainer from '@crema/components/AppGridContainer';
import Grid from '@mui/material/Grid';
import AppCard from '@crema/components/AppCard';

import AppSelect from '@crema/components/AppSelect';
import { useIntl } from 'react-intl';
import AppList from '@crema/components/AppList';
import Avatar from '@mui/material/Avatar';
import MixBarChart from './MixBarChart';
import IntlMessages from '@crema/helpers/IntlMessages';
import { ccyFormat } from '@crema/helpers/Common';

const SalesState = ({ salesState, chartData }) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const getData = (data) => {
    return data;
  };
  const { messages } = useIntl();
  return (
    <AppCard
      title={
        <IntlMessages id={'dashboard.salesState.title'} />}
      sxStyle={{ height: 1 }}
      action={
        <AppSelect
          menus={[
            messages['dashboard.thisYear'],
          ]}
          defaultValue={messages['dashboard.thisYear']}
          onChange={handleSelectionType}
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          component='p'
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
            color: 'text.secondary',
            mt: -3,
            mb: 2,
          }}
        >
          <IntlMessages id={'dashboard.salesState.subTitle'} />
        </Box>

        <Box
          sx={{
            mt: 'auto',
          }}
        >
          <AppGridContainer>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: { xs: 'center', sm: 'flex-start' },
                }}
              >
                <MixBarChart data={chartData} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                sx={{
                  width: 1,
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <AppList
                  animation='transition.slideRightBigIn'
                  delay={200}
                  duration={400}
                  containerStyle={{ width: '100%', overflow: 'hidden' }}
                  data={getData(salesState)}
                  renderRow={(item) => (
                    <Box
                      key={'salesState-' + item.id}
                      sx={{
                        pl: { xl: 0 },
                        py: { xs: 2, md: 4 },
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Avatar
                        src={item.icon}
                        alt='icon'
                        sx={{ height: 50, width: 50 }}
                      />

                      <Box
                        position='relative'
                        sx={{
                          ml: 4,
                          overflow: 'hidden',
                          width: 'calc(100% - 66px)',
                        }}
                      >
                        <Box
                          component='h3'
                          sx={{
                            display: 'inline-block',
                            fontWeight: Fonts.MEDIUM,
                            mb: 0.5,
                            fontSize: 16,
                          }}
                        >
                          {ccyFormat(item.amount)}
                        </Box>
                        <Box
                          component='p'
                          sx={{
                            color: 'text.secondary',
                            fontSize: 14,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%',
                          }}
                        >
                          <IntlMessages id={'dashboard.salesState.' + item.type} />
                        </Box>
                      </Box>
                    </Box>
                  )}
                />
              </Box>
            </Grid>
          </AppGridContainer>
        </Box>
      </Box>
    </AppCard>
  );
};
export default SalesState;

SalesState.defaultProps = {
  salesState: [],
};

SalesState.propTypes = {
  salesState: PropTypes.array,
  chartData: PropTypes.array,
};
