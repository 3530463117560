import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Place = React.lazy(() => import('../../../modules/place'));
const ViewRecord = React.lazy(() => import('../../../modules/place/PlaceViewRecord'));

export const placePagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/places',
      '/places/:id'
    ],
    element: <Place />,
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/places/:id/view-record'
    ],
    element: <ViewRecord />,
  }
];
