import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSocket } from 'socket';

export function Event({
  event,
  handler
}) {
  if (typeof window === 'undefined') return null;

  const { getSocket, socketStatus, connected } = useSocket();

  const handleOffSocket = () => {
    const socket = getSocket();
    socket?.off(event, handler);
  };

  useEffect(() => {
    // console.log('socketStatus', socketStatus);
    if (!connected()) return handleOffSocket();
    const socket = getSocket();
    socket?.on(event, handler);

    return handleOffSocket;
  }, [socketStatus]);

  return null;
}

Event.propTypes = {
  handleRouterChange: PropTypes.bool,
  handler: PropTypes.func,
  event: PropTypes.string
};

export default Event;
