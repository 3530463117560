import {
  GET_DASHBOARD_RECORD_HOUR_LIST,
  GET_DASHBOARD_SALE_BY_YEAR,
  GET_DASHBOARD_SALE_BY_MONTH,
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  recordHour: [],
  saleYears: [],
  saleMonths: [],
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_DASHBOARD_RECORD_HOUR_LIST, (state, action) => {
      state.recordHour = action.payload ?? [];
    })
    .addCase(GET_DASHBOARD_SALE_BY_YEAR, (state, action) => {
      state.saleYears = action.payload;
    })
    .addCase(GET_DASHBOARD_SALE_BY_MONTH, (state, action) => {
      state.saleMonths = action.payload;
    })
});

export default dashboardReducer;
