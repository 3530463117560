import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import { Box, Hidden, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import { Fonts } from '@crema/constants/AppEnums';
import AppCard from '@crema/components/AppCard';
import ListingTable from './ListingTable';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import { useDispatch, useSelector } from 'react-redux';
import { onDeleteSelectedRecords, onGetRecordList } from 'toolkit/actions';
import IntlMessages from '@crema/helpers/IntlMessages';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Event from 'socket/Event';

const RecordListing = () => {
  const { user } = useAuthUser();
  const { messages } = useIntl();

  const [checkedItems, setCheckedItems] = useState([]);

  const loading = useSelector(({ common }) => common.loading);

  const records = useSelector(({ record }) => record.items);

  const count = useSelector(({ record }) => record.count);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState({
    q: '',
    page: 0,
    take: 5,
    companyId: user.companyId
  });

  useEffect(() => {
    dispatch(onGetRecordList(queryParams));
  }, [dispatch, queryParams]);

  const onPageChange = (event, value) => {
    setQueryParams({
      ...queryParams, page: value
    })
  };

  const onDeleteRecord = async (id) => {
    dispatch(onDeleteSelectedRecords([id], user.companyId));
  };


  const onChangeCheckedRecords = (event, id) => {
    if (event.target.checked) {
      setCheckedItems(checkedItems.concat(id));
    } else {
      setCheckedItems(checkedItems.filter((rId) => rId !== id));
    }
  };

  const onGetNewRecord = () => {
    dispatch(onGetRecordList(queryParams))
  }

  return (
    <>
      <Event event='new_record' handler={() => onGetNewRecord()} />
      <AppCard
        title={
          <AppsHeader>
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              width={1}
              justifyContent='space-between'
            >
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
              >
                <Typography
                  component='h2'
                  variant='h2'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 3.5,
                    fontSize: 16,
                    fontWeight: Fonts.MEDIUM,
                    mb: { xs: 3, sm: 0 },
                  }}
                >
                  <IntlMessages id='dashboard.totalRecordToday' />
                </Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-end'
                onClick={() => navigate('/report')}
              >
                <Typography
                  component='h2'
                  variant='h2'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 3.5,
                    fontSize: 14,
                    fontWeight: Fonts.MEDIUM,
                    mb: { xs: 3, sm: 0 },
                    color: 'red',
                    cursor: 'pointer'
                  }}
                >
                  <IntlMessages id='dashboard.viewRecordList' />
                </Typography>
              </Box>
            </Box>
          </AppsHeader>
        }
        headerStyle={{ p: 0 }}
        contentStyle={{ p: 0 }}
      >
        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <ListingTable recordData={records || []} loading={loading} onDelete={onDeleteRecord} checkedRecords={checkedItems} onChangeCheckedRecords={onChangeCheckedRecords} />
        </AppsContent>
        <Hidden smUp>
          <AppsPagination
            rowsPerPage={10}
            count={count}
            page={queryParams.page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppCard>
    </>
  );
};

export default RecordListing;
