import {
  CREATE_NEW_COMMODITY,
  GET_COMMODITY_DETAIL,
  GET_COMMODITY_LIST,
  UPDATED_COMMODITY
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedCommodity: null
};

const commodityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_COMMODITY_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_COMMODITY, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_COMMODITY_DETAIL, (state, action) => {
      state.selectedCommodity = action.payload;
    })
    .addCase(UPDATED_COMMODITY, (state, action) => {
      const selectedCommodity = action.payload;
      state.selectedCommodity = selectedCommodity;
      const index = state.items.findIndex(item => item._id === selectedCommodity._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedCommodity;
        state.items = nextList;
      }
    })
});

export default commodityReducer;
