import React from 'react';

import { Box, Icon, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fonts } from '@crema/constants/AppEnums';
import AppCard from '@crema/components/AppCard';
import { getAssetsUrl } from '@crema/helpers/UrlHelper';

const StatsDirCard = ({ data }) => {
  return (
    <AppCard
      className='card-hover'
      contentStyle={{
        p: 0,
        '&:last-of-type': {
          pb: 0,
        },
        mb: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component='span'
            sx={{
              mr: 3.5,
              height: 46,
              width: 46,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              color: data.color,
              backgroundColor: data.color + '22',
            }}
          >
            {data?.icon ? (
              <Icon
                sx={{
                  fontSize: 24,
                }}
              >
                {data.icon}
              </Icon>
            ) : (
              <img src={getAssetsUrl(data.iconImg)} alt='' />
            )}
          </Box>

          <Box sx={{ mr: 2, overflow: 'hidden' }}>
            <Typography
              variant='h2'
              component='h2'
              sx={{
                fontWeight: Fonts.SEMI_BOLD,
                fontSize: 18,
              }}
            >
              {data.value}
            </Typography>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 14,
                color: (theme) => theme.palette.text.secondary,
                mt: 0.5,
              }}
            >
              {data.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </AppCard>
  );
};

export default StatsDirCard;

StatsDirCard.propTypes = {
  data: PropTypes.object,
};
