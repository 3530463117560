import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import Dashboard from '../../../modules/dashboard';

export const accountPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.CompanyAdmin],
    path: '/my-profile',
    element: <Account />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.CompanyAdmin],
    path: '/dashboard',
    element: <Dashboard />,
  },
];
