import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
// import {onCreateRecord} from '../../../../toolkit/actions';
import { useDispatch } from 'react-redux';
import IntlMessages from '@crema/helpers/IntlMessages';
import PropTypes from 'prop-types';
import AddRecordForm from './RecordForm';
import { onUpdateRecord } from 'toolkit/actions';
import { pick } from 'lodash';

const validationSchema = yup.object({
  price: yup.number(<IntlMessages id='record.validation.priceInvalid' />).required(<IntlMessages id='record.validation.priceRequired' />).min(1, <IntlMessages id='record.validation.priceMinRequired' />),
  quantity: yup.number(<IntlMessages id='record.validation.quantityInvalid' />).required(<IntlMessages id='record.validation.quantityRequired' />).min(1, <IntlMessages id='record.validation.quantityMinRequired' />),
  amount: yup.number(<IntlMessages id='record.validation.amountInvalid' />).required(<IntlMessages id='record.validation.amountRequired' />).min(1, <IntlMessages id='record.validation.amountMinRequired' />),
  productType: yup.string().required(<IntlMessages id='validation.productTypeRequired' />),
  unit: yup.string().required(<IntlMessages id='validation.unitRequired' />),
});

export const recordFields = [
  'price',
  'amount',
  'quantity',
  'productType',
  'unit',
  'licensePlate',
  'note',
]

const UpdateRecord = ({ record }) => {
  const dispatch = useDispatch();

  const invoice = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}records/${record._id}/invoice/?access_token=${localStorage.getItem('token')}`;
    window.open(url, '_blank').focus();
  }
  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        ...record
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        dispatch(onUpdateRecord(record._id, pick(data, recordFields)));
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, setFieldValue, errors }) => (
        <AddRecordForm
          isSubmitting={isSubmitting}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          getInvoice={invoice}
        />
      )}
    </Formik>
  );
};

export default UpdateRecord;

UpdateRecord.propTypes = {
  record: PropTypes.object
};
