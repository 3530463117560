import enMessages from '../locales/vi_VN.json';
import { viVN } from '@mui/material/locale';

const ViLang = {
  messages: {
    ...enMessages,
  },
  muiLocale: viVN,
  locale: 'vi-VN',
};
export default ViLang;
