import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { ccyFormat } from '@crema/helpers/Common';
import IntlMessages from '@crema/helpers/IntlMessages';
import { useIntl } from 'react-intl';

const RecordHourGraph = ({ data }) => {
  if (data)
    data = [...data].sort((a, b) => a.time - b.time)
  const { messages } = useIntl();
  return (
    <ResponsiveContainer width='100%' height={240}>
      <BarChart data={data}

        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        {/* <YAxis tickFormatter={(value) => ccyFormat(value + "0")} type="number" /> */}
        <Tooltip formatter={(value) => ccyFormat(value)} />
        {/* <Legend /> */}
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <Bar dataKey='value' name={messages['dashboard.salesState.chart.count'] ?? "dashboard.salesState.chart.count"} fill='#49bd65' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RecordHourGraph;

RecordHourGraph.defaultProps = {
  data: [],
};

RecordHourGraph.propTypes = {
  data: PropTypes.array,
};
