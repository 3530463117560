//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const SHOW_ERROR_MESSAGE = 'show_error_message';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//HEATH_ CARE-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//COMPANY
export const GET_COMPANY_LIST = 'get_company_list';
export const CREATE_NEW_COMPANY = 'create_new_company';
export const DELETE_COMPANY = 'delete_company';
export const GET_COMPANY_DETAIL = 'get_company_detail';
export const UPDATED_COMPANY = 'updated_company';
export const GET_COMPANY_SETTINGS = 'get_company_settings';
// export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
// export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
// export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
// export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
// export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
// export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
// export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
// export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
// export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
// export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
// export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//DEVICE
export const GET_DEVICE_LIST = 'get_device_list';
export const CREATE_NEW_DEVICE = 'create_new_device';
export const DELETE_DEVICE = 'delete_device';
export const GET_DEVICE_DETAIL = 'get_device_detail';
export const UPDATED_DEVICE = 'updated_device';

//CAMERA
export const GET_CAMERA_LIST = 'get_camera_list';
export const CREATE_NEW_CAMERA = 'create_new_camera';
export const DELETE_CAMERA = 'delete_camera';
export const GET_CAMERA_DETAIL = 'get_camera_detail';
export const UPDATED_CAMERA = 'updated_camera';
export const GET_ALL_CAMERA_LIST = 'get_all_camera_list';
export const GET_DASHBOARD_CAMERAS = 'get_cameras_dashboard';
export const SET_DASHBOARD_CAMERAS = 'set_dashboard_cameras';

//USER
export const GET_USER_LIST = 'get_user_list';
export const CREATE_NEW_USER = 'create_new_user';
export const DELETE_USER = 'delete_user';
export const GET_USER_DETAIL = 'get_user_detail';
export const UPDATED_USER = 'updated_user';


//UTIL
export const GET_CITY_LIST = 'get_city_list';
export const GET_DISTRICT_LIST = 'get_district_list'
export const GET_WARD_LIST = 'get_ward_list'

//RECORD
export const GET_RECORD_LIST = 'get_record_list';
export const DELETE_RECORD = 'delete_record';
export const GET_RECORD_DETAIL = 'get_record_detail';
export const UPDATED_RECORD = 'updated_record';
export const GET_RECORD_STATS = 'get_record_stats';

// CATEGORY
export const GET_CATEGORY_LIST = 'get_category_list';
export const CREATE_NEW_CATEGORY = 'create_new_category';
export const DELETE_CATEGORY = 'delete_category';
export const GET_CATEGORY_DETAIL = 'get_category_detail';
export const UPDATED_CATEGORY = 'updated_category';

//Notification
export const GET_NOTIFICATION = 'get_notification';
export const RESET_NOTIFICATION = 'reset_notification';
export const LOADING_NOTIFICATION = 'loading_notification';
export const READ_NOTIFICATION = 'read_notification';
export const READ_ALL_NOTIFICATION = 'read_all_notification';
export const DELETE_NOTIFICATION = 'delete_notification';
export const DELETE_ALL_NOTIFICATION = 'delete_all_notification';
export const NO_LINK_NOTIFICATION = 'no_link_notification';

//RECORD
export const GET_DASHBOARD_RECORD_HOUR_LIST = 'get_dashboard_record_hour_list';
export const GET_DASHBOARD_SALE_BY_YEAR = 'get_dashboard_sale_by_year';
export const GET_DASHBOARD_SALE_BY_MONTH = 'get_dashboard_sale_by_month';

// DRIVER
export const GET_DRIVER_LIST = 'get_driver_list';
export const CREATE_NEW_DRIVER = 'create_new_driver';
export const DELETE_DRIVER = 'delete_driver';
export const GET_DRIVER_DETAIL = 'get_driver_detail';
export const UPDATED_DRIVER = 'updated_driver';

// VEHICLE
export const GET_VEHICLE_LIST = 'get_vehicle_list';
export const CREATE_NEW_VEHICLE = 'create_new_vehicle';
export const DELETE_VEHICLE = 'delete_vehicle';
export const GET_VEHICLE_DETAIL = 'get_vehicle_detail';
export const UPDATED_VEHICLE = 'updated_vehicle';

// PLACE
export const GET_PLACE_LIST = 'get_place_list';
export const CREATE_NEW_PLACE = 'create_new_place';
export const DELETE_PLACE = 'delete_place';
export const GET_PLACE_DETAIL = 'get_place_detail';
export const UPDATED_PLACE = 'updated_place';

// TRIP
export const GET_TRIP_LIST = 'get_trip_list';
export const CREATE_NEW_TRIP = 'create_new_trip';
export const DELETE_TRIP = 'delete_trip';
export const GET_TRIP_DETAIL = 'get_trip_detail';
export const UPDATED_TRIP = 'updated_trip';

// DESTINATION
export const GET_DESTINATION_LIST = 'get_destination_list';
export const CREATE_NEW_DESTINATION = 'create_new_destination';
export const DELETE_DESTINATION = 'delete_destination';
export const GET_DESTINATION_DETAIL = 'get_destination_detail';
export const UPDATED_DESTINATION = 'updated_destination';

// COMMODITY
export const GET_COMMODITY_LIST = 'get_commodity_list';
export const CREATE_NEW_COMMODITY = 'create_new_commodity';
export const DELETE_COMMODITY = 'delete_commodity';
export const GET_COMMODITY_DETAIL = 'get_commodity_detail';
export const UPDATED_COMMODITY = 'updated_commodity';
