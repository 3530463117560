import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useLayoutContext } from '@crema/context/AppContextProvider/LayoutContextProvider';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Link } from 'react-router-dom';

const AppFixedFooter = () => {
  const { footer, footerType } = useLayoutContext();

  return (
    <>
      {footer && footerType === 'fixed' ? (
        <FooterWrapper className='footer fixed-footer'>
          <div className='footerContainer'>
            <Typography><IntlMessages id='footer.copyRight' /></Typography>
            <Box sx={{ ml: 'auto' }}>
              <Link to="tel:0912555193" color='primary'><IntlMessages id='footer.about' /></Link>
            </Box>
          </div>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFixedFooter;
