import {
  CREATE_NEW_DRIVER,
  GET_DRIVER_DETAIL,
  GET_DRIVER_LIST,
  UPDATED_DRIVER
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedDriver: null
};

const driverReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_DRIVER_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_DRIVER, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_DRIVER_DETAIL, (state, action) => {
      state.selectedDriver = action.payload;
    })
    .addCase(UPDATED_DRIVER, (state, action) => {
      const selectedDriver = action.payload;
      state.selectedDriver = selectedDriver;
      const index = state.items.findIndex(item => item._id === selectedDriver._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedDriver;
        state.items = nextList;
      }
    })
});

export default driverReducer;
