import {
  CREATE_NEW_VEHICLE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_VEHICLE_DETAIL,
  GET_VEHICLE_LIST,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_VEHICLE
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';

export const onGetVehicleList = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('vehicles', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_VEHICLE_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateVehicle = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('vehicles', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_VEHICLE, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['vehicle.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });
  };
};

export const onDeleteSelectedVehicles = (vehicleIds = [], group) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await vehicleIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`vehicles/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetVehicleList({ name: '', group }))
  };
};

export const onGetVehicleDetail = (vehicleId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`vehicles/${vehicleId}`)).data;
      dispatch({ type: GET_VEHICLE_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateVehicle = (vehicleId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`vehicles/${vehicleId}`, payload)).data;
      dispatch({ type: GET_VEHICLE_DETAIL, payload: data });
      dispatch({ type: UPDATED_VEHICLE, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['vehicle.created'],
      });
      dispatch(showMessage(messages['vehicle.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};