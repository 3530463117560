export const accountFields = [
  'name',
  'phoneNumber',
  'email',
  'address',
  'city',
  'district',
  'ward',
  'password',
  'avatar',
]