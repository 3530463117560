import React from 'react';
import { useInfoViewActionsContext } from '@crema/context/AppContextProvider/InfoViewContextProvider';
import { uploadPostDataApi, uploadPutDataApi } from '@crema/hooks/APIHooks';
import { Avatar, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { ThemeStyle } from '@crema/constants/AppEnums';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'toolkit/actions';
import { getError } from '@crema/helpers/ApiHelper';

const AvatarViewWrapper = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&:hover .edit-icon, &:focus .edit-icon': {
      display: 'flex',
    },
  };
});

function ImageUpload({ url, onUploaded, method = 'POST', accept, imageUrl, style }) {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: async (acceptedFiles) => {
      if (!url) return;
      try {
        dispatch(fetchStart())
        const resp = method === 'POST' ?
          await uploadPostDataApi(url, { file: acceptedFiles[0] }) :
          await uploadPutDataApi(url, { file: acceptedFiles[0] });
        const { data } = resp;
        dispatch(fetchSuccess());
        if (data && data._id) {
          onUploaded(data);
          dispatch(showMessage('Uploaded success'));
        }

      } catch (error) {
        dispatch(fetchError(getError(error)));
      }
    },
  });
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <label htmlFor='icon-button-file'>
        <AvatarViewWrapper >
          <Avatar
            sx={{
              width: { xs: 50, lg: 80 },
              height: { xs: 50, lg: 80 },
              cursor: 'pointer',
              ...style
            }}
            src={imageUrl}
          />
          <Box className='edit-icon'>
            <EditIcon />
          </Box>
        </AvatarViewWrapper>
      </label>
    </div>
  )
}

ImageUpload.propTypes = {
  onUploaded: PropTypes.func,
  url: PropTypes.string,
  accept: PropTypes.string,
  method: PropTypes.string,
  imageUrl: PropTypes.string,
  style: PropTypes.object,
};
ImageUpload.defaultProps = {
  url: 'media/photos',
  accept: 'image/*',
  imageUrl: '/assets/images/placeholder.jpg',
  onUploaded: () => null,
  style: {
    width: { xs: 50, lg: 80 },
    height: { xs: 50, lg: 80 },
    cursor: 'pointer'
  }
};

export default ImageUpload;