import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Company = React.lazy(() => import('../../../modules/company'));
const MyCompany = React.lazy(() => import('../../../modules/company-admin'));
const Dashboard = React.lazy(() => import('../../../modules/company-dashboard'));
const Report = React.lazy(() => import('../../../modules/report'));
export const companyPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer],
    path: [
      '/company',
      '/company/:id'
    ],
    element: <Company />,
  },
  {
    permittedRole: [RoutePermittedRole.CompanyAdmin],
    path: [
      '/my-company'
    ],
    element: <MyCompany />,
  },
  {
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
    path: [
      '/view-device'
    ],
    element: <Dashboard />,
  },
  {
    permittedRole: [RoutePermittedRole.CompanyAdmin, RoutePermittedRole.User],
    path: [
      '/report'
    ],
    element: <Report />,
  }
];
