import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Vehicle = React.lazy(() => import('../../../modules/vehicle'));

export const vehiclePagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/vehicles',
      '/vehicles/:id'
    ],
    element: <Vehicle />,
  }
];
