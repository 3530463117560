import React, { useEffect } from 'react';
import { Divider, IconButton, Pagination, Stack } from '@mui/material';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import IntlMessages from '@crema/helpers/IntlMessages';
import NotificationItem from './NotificationItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppScrollbar from '../AppScrollbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  onDeleteAllNotification,
  onGetNotification,
  onReadAllNotification,
} from 'toolkit/actions/Notification';
import AppLoader from '../AppLoader';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AppConfirmDialog from '../AppConfirmDialog';

const AppNotificationContent = ({ onClose, sxStyle }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handlePageChange = (value) => {
    setPage(value);
    dispatch(onGetNotification(value));
  };

  const notifications = useSelector(
    ({ notification }) => notification.notifications,
  );

  const allNotificationRead = notifications.every(
    (notification) => notification.unreadNotification !== 1,
  );
  const loading = useSelector((state) => state.notification.loading);
  const total = useSelector((state) => state.notification.total);

  const markAllAsRead = () => {
    dispatch(onReadAllNotification());
  };

  const deleteAllNoti = () => {
    setOpenConfirm(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: {
            xs: '100vw',
            sm: '420px',
          },
          height: '100%',
          overflowY: 'hidden',
          ...sxStyle,
        }}
      >
        <Box
          sx={{
            padding: '5px 20px',
            alignItems: 'center',
            py: 3,
            borderBottom: 1,
            borderBottomColor: (theme) => theme.palette.divider,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography component='h3' variant='h3'>
              <IntlMessages id='common.notifications' />({total})
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{
                  height: 40,
                  width: 40,
                  marginLeft: 'auto',
                  color: 'text.secondary',
                }}
                onClick={onClose}
                size='large'
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {notifications.length === 0 ? (
          loading ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AppLoader />
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Chưa có thông báo nào
            </Box>
          )
        ) : (
          <Box
            sx={{
              overflowY: 'auto',
              position: 'relative',
              height:
                notifications.length >= total
                  ? '100%'
                  : 'calc(100% - 110px)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '6px 20px',
              }}
            >
              {!allNotificationRead && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={markAllAsRead}
                >
                  <DoneAllIcon
                    sx={{ fontSize: '16px', mr: 1, color: '#2997ff' }}
                  />
                  <Typography
                    sx={{ fontSize: '13px', marginBottom: 0, color: '#2997ff' }}
                  >
                    <IntlMessages id='mailApp.markAllAsRead' />
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{
                  fontSize: '13px',
                  marginBottom: 0,
                  color: 'red',
                  textAlign: 'right',
                  cursor: 'pointer',
                  ml: 'auto',
                }}
                onClick={deleteAllNoti}
              >
                <IntlMessages id='notification.deleteAll' />
              </Typography>
            </Box>

            {loading && (
              <AppLoader
                sx={{
                  position: 'absolute',
                  top: '50%',
                  bottom: '50%',
                }}
              />
            )}
            <List sx={{ py: 0 }}>
              {notifications.map((item) => (
                <NotificationItem key={item.id} item={item} onClose={onClose} />
              ))}
            </List>
          </Box>
        )}

        {notifications.length < total && (
          <Button
            sx={{
              borderRadius: 0,
              width: '100%',
              textTransform: 'capitalize',
              marginTop: 'auto',
              height: 40,
            }}
            disabled={loading}
            variant='contained'
            color='primary'
            onClick={() => {
              handlePageChange(page + 1);
            }}
          >
            <IntlMessages id='common.loadMore' />
          </Button>
        )}
      </Box>
      <AppConfirmDialog
        open={openConfirm}
        onDeny={() => {
          setOpenConfirm(false);
        }}
        onConfirm={() => {
          setOpenConfirm(false);
          dispatch(onDeleteAllNotification());
        }}
        title={<IntlMessages id='notification.deleteAllNotification' />}
        dialogTitle={<IntlMessages id='notification.deleteNotification' />}
      />
    </>
  );
};

export default AppNotificationContent;

AppNotificationContent.propTypes = {
  onClose: PropTypes.func,
  sxStyle: PropTypes.object,
};
