import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const Trip = React.lazy(() => import('../../../modules/trip'));
const DestinationDetail = React.lazy(() => import('../../../modules/trip/DestinationDetail'));
const TripReport = React.lazy(() => import('../../../modules/trip-report'));

export const tripPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/trips',
      '/trips/:id'
    ],
    element: <Trip />,
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/destinations/:id'
    ],
    element: <DestinationDetail />,
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.CompanyAdmin],
    path: [
      '/trip-report'
    ],
    element: <TripReport />,
  },
];
