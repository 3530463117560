import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useLayoutContext } from '@crema/context/AppContextProvider/LayoutContextProvider';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Link } from 'react-router-dom';

const AppFooter = () => {
  const { footer, footerType, navStyle } = useLayoutContext();

  return (
    <>
      {footer &&
        footerType === 'fluid' &&
        navStyle !== 'h-default' &&
        navStyle !== 'hor-light-nav' &&
        navStyle !== 'hor-dark-layout' ? (
        <FooterWrapper className='footer'>
          <div className='footerContainer'>
            <Typography><IntlMessages id='footer.copyRight' /></Typography>
            <Box sx={{ ml: 'auto' }}>
              <Link to="tel:0912555193" color='primary'><IntlMessages id='footer.about' /></Link>
            </Box>
          </div>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFooter;
