import {
  CREATE_NEW_CAMERA,
  GET_ALL_CAMERA_LIST,
  GET_CAMERA_DETAIL,
  GET_CAMERA_LIST,
  GET_DASHBOARD_CAMERAS,
  SET_DASHBOARD_CAMERAS,
  UPDATED_CAMERA
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedCamera: null,
  allCameras: [],
  dashboardCameras: []
};

const cameraReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_CAMERA_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_CAMERA, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_CAMERA_DETAIL, (state, action) => {
      state.selectedCamera = action.payload;
    })
    .addCase(UPDATED_CAMERA, (state, action) => {
      const selectedCamera = action.payload;
      state.selectedCamera = selectedCamera;
      const index = state.items.findIndex(item => item._id === selectedCamera._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedCamera;
        state.items = nextList;
      }
    })
    .addCase(GET_ALL_CAMERA_LIST, (state, action) => {
      state.allCameras = action.payload.items ?? [];
    })
    .addCase(GET_DASHBOARD_CAMERAS, (state, action) => {
      state.dashboardCameras = action.payload.items ?? [];
    })
    .addCase(SET_DASHBOARD_CAMERAS, (state, action) => {
      state.dashboardCameras = action.payload ?? [];
    })
});

export default cameraReducer;
