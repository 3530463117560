import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import AppTableContainer from '@crema/components/AppTableContainer';
import ViewRecord from './ViewRecord';

const RecordTable = ({ recordData, loading, onDelete, checkedRecords, onChangeCheckedRecords }) => {
  const [selectedRecord, setRecord] = useState(null);

  const onViewRecord = (record) => {
    setRecord(record)
  }
  return (
    <AppTableContainer>
      <Table stickyHeader className='table'>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {recordData.map((data) => <TableItem data={data} key={data._id} onDelete={onDelete} checkedRecords={checkedRecords} onChangeCheckedRecords={onChangeCheckedRecords} onViewRecord={() => onViewRecord(data)} />)}
        </TableBody>
      </Table>
      {selectedRecord && (<ViewRecord isOpen={!!selectedRecord} onClose={() => setRecord(null)} record={selectedRecord} />)}
    </AppTableContainer>
  );
};

export default RecordTable;

RecordTable.defaultProps = {
  recordData: [],
};

RecordTable.propTypes = {
  recordData: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  checkedRecords: PropTypes.array,
  onChangeCheckedRecords: PropTypes.func
};
