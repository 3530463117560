import {
  CREATE_NEW_TRIP,
  GET_TRIP_DETAIL,
  GET_TRIP_LIST,
  UPDATED_TRIP
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedTrip: null
};

const tripReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_TRIP_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_TRIP, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_TRIP_DETAIL, (state, action) => {
      state.selectedTrip = action.payload;
    })
    .addCase(UPDATED_TRIP, (state, action) => {
      const selectedTrip = action.payload;
      state.selectedTrip = selectedTrip;
      const index = state.items.findIndex(item => item._id === selectedTrip._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedTrip;
        state.items = nextList;
      }
    })
});

export default tripReducer;
